import React from 'react'
import Slider from 'react-slick'
import { intersection, isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import { Icons } from '../../components/Icons'
import meganoticias from '../../assets/icons/meganoticias.svg'
import { CardInfo } from '../../components/CardInfo'
import { CardThumb } from '../../components/CardThumb'
import { CardNews } from '../../components/CardNews'
import { CardPortrait } from '../../components/CardPortrait'
import HomeHero from './HomeHeroContainer'

export default function Home(props) {
  const {
    cardImageSrc,
    handleOnChangePreferences,
    handleOnClickWatchNow,
    lists,
    news,
    preferencesKeyedById,
    sliderNewsSettings,
    sliderSettings,
    slidesToShow,
    slidesToScroll,
    sliderRowStyle,
    subscriptionsPlanIds,
    navigate,
    useImageCDN,
    sponsored,
  } = props

  return (
    <main
      className={
        `appWrap fixedHero${lists?.filter(
          list => list.type === 'Highlighted' || list.isHighlighted)[0]?.content?.length > 1
          ? ' fixed-hero-container'
          : ''
        }`
      }
    >
      {lists.map(row => (
        (row.type === 'Highlighted' || row.isHighlighted) && row.content.length !== 0 ? (
          <HomeHero
            row={row}
            key={row._id}
            handleOnClickWatchNow={handleOnClickWatchNow}
            navigate={navigate}
            useImageCDN={useImageCDN}
            sponsored={sponsored}
          />
        ) : row.type === 'News' ? news.length > 0 && (
          <section key={row._id} className='rowXxl horizontalRow'>
            <header className='rowS mainWidth'>
              <h2>{row.title}<Icons path={meganoticias} /></h2>
            </header>
            <Slider {...sliderNewsSettings} className='newsSlider'>
              {(news).map(item => (
                <li key={item.url} className='articleListItem'>
                  <CardNews title={item.titulo} link={item.url} imgUrl={item.imagen} />
                </li>
              ))}
            </Slider>
          </section>
        ) : row.style === 'tiles' ?
          <section key={row._id} className='rowXxl horizontalRow listTilesWrap'>
            <div className='mainWidth'>
              <ul className='grid'>
                {(row.content).map(content => (
                  <li key={content._id} className='articleListItem'>
                    <CardThumb
                      cardType={content.type}
                      contentId={content._id}
                      image={!isEmpty(content.imagesSite.thumbnail)
                        ? useImageCDN
                          ? content.imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                          : content.imagesSite.thumbnail
                        : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
                      }
                      isUnlocked={content.type === 'Live' ? !isEmpty(intersection(subscriptionsPlanIds, content.planIds || [])) : true}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </section>
          : row.content.length > 0 &&
          <section key={row._id} className={`rowXxl horizontalRow ${sliderRowStyle(row.style)}`}>
              <header className='rowS mainWidth'>
                <h2>{row.title}</h2>
              </header>
              <Slider {...sliderSettings} slidesToShow={slidesToShow(row.style)} slidesToScroll={slidesToScroll(row.style)}>
                {(row.content).map((content, index) => (
                  <div key={content._id} className='articleListItem'>
                    {row.type === 'Vod' ?
                      <CardInfo
                        vod={content}
                        isHome
                        isUnlocked={!isEmpty(intersection(subscriptionsPlanIds, content.serie?.planIds || []))}
                        pay={content.pay}
                        showSeason={content.serie?.seasons?.length > 1 || false}
                        useImageCDN={useImageCDN}
                      />
                      :
                      <CardPortrait
                        cardImageSrc={cardImageSrc}
                        content={content}
                        handleOnChangePreferences={handleOnChangePreferences}
                        handleOnClickWatchNow={handleOnClickWatchNow}
                        preference={preferencesKeyedById[content._id]}
                        cardIndex={index + 1}
                        isUnlocked={content.type === 'Live' ? !isEmpty(intersection(subscriptionsPlanIds, content.planIds || [])) : true}
                        listStyle={row.style}
                        listType={row.type}
                      />
                    }
                  </div>
                ))}
                {/*
                TODO: SI es mayor el número de objetos al número fijo de slides en options,
                */}
                {row.content.length > 1 &&
                  <div key='last-item-placeholder' className='articleListItem'></div>
                }
              </Slider>
            </section>
      ))}
      <Footer />
    </main>
  )
}