import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useSearchParams } from 'react-router-dom'

import SearchContainer from '../../containers/search/SearchContainer'
import Loading from '../Loading'

import {
  GET_SERIES,
  GET_EVENTS,
} from '../../redux/actions'

export default memo(function CategoriesComponent() {
  const { isAuthenticated, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth)
  const { data: series, isLoading: isLoadingSeries } = useSelector(state => state.series)
  const { data: events, isLoading: isLoadingEvents } = useSelector(state => state.events)

  const [mergedShows, setMergedShows] = useState([])
  let [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const limit = 12
  const page = 1

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    isEmpty(series) && dispatch(GET_SERIES({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isEmpty(events) && dispatch(GET_EVENTS({ keycloak, limit, page }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isLoadingEvents && !isLoadingSeries) {
      if (!isEmpty(events)) {
        setMergedShows([...events, ...series])
      } else {
        setMergedShows([...series])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEvents, isLoadingSeries])

  // Set image size based on list style
  const cardImageSrc = (item) => {
    return !useImageCDN ? item.imagesSite?.poster : item.imagesSite?.poster?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
  }

  useEffect(() => {
    document.title = 'Mega GO | Buscador'
    return () => {
      document.title = 'Mega GO'
    }
  }, [])

  return (
    isLoadingSeries || isLoadingEvents || isEmpty(mergedShows)
      ?
      <Loading />
      :
      <SearchContainer
        cardImageSrc={cardImageSrc}
        mergedShows={mergedShows}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
  )
})