import React,
{ useState } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'


import { CHECK_ALL_NOTIFICATIONS } from './../redux/actions'
import Notifications from './Notifications'
import { Icons } from '../components/Icons'
import megaGo from '../assets/icons/megaGo.svg'
import iconHome from '../assets/icons/house.svg'
import iconCategory from '../assets/icons/menu.svg'
import iconStar from '../assets/icons/star.svg'
import iconSearch from '../assets/icons/search.svg'
import iconBell from '../assets/icons/bell.svg'
import iconPhone from '../assets/icons/phoneCall.svg'
import { useScrollPosition } from '../hooks/useScrollPosition'

export default function NavMenu({ currentProfile }) {
  const { firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth);
  const { data: notifications } = useSelector(state => state.notifications);

  const [anchorEl, setAnchorEl] = useState(false);
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(true)
  }
  
  const handleClose = () => {
    setAnchorEl(false)
    dispatch(CHECK_ALL_NOTIFICATIONS())
  }

  const unCheckedNotifications = notifications.filter(notification => !notification.isChecked);
  const scrollPosition = useScrollPosition();

  return (
    <>
      <div className={scrollPosition > 100 ? 'fixedHeader mainNavWrap modal' : 'mainNavWrap modal'}>
        <div className='navBox'>
          <div className='leftContent'>
            <Link to='/inicio' className='mainSiteLogo'><Icons path={megaGo} /><h1>Mega GO</h1></Link>
            <Link to='/inicio' className='navLink'><Icons path={iconHome} />Inicio</Link>
            <Link to='/categorias' className='navLink'><Icons path={iconCategory} />Categorías</Link>
            <Link to='/mi-lista' className='navLink'><Icons path={iconStar} />Mi lista</Link>
          </div>
          <div className='rightContent'>
            <Link to='/contactanos' className='navLink outlineNavLink'><Icons path={iconPhone} />Contáctanos</Link>
            <Link to='/buscador'><IconButton><Icons path={iconSearch} /></IconButton></Link>
            <div className='notifyPop'>
              {unCheckedNotifications.length > 0 && <span className='notifyNumber'>{unCheckedNotifications.length}</span>}
              <IconButton onClick={handleClick}><Icons path={iconBell} /></IconButton>
            </div>
            <Link to='/cuenta' className='userLink'>
              <div className='userAvatar'>
                <img
                  src={!isEmpty(currentProfile.avatar?.imageUrl)
                    ? useImageCDN
                      ? currentProfile.avatar?.imageUrl.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                      : currentProfile.avatar?.imageUrl
                    : 'https://api-megago.megamedia.cl/files/avatar1.png'
                  }
                  alt={currentProfile.avatar?.name || ''}
                />
              </div>
              <p>Mi perfil</p>
            </Link>
          </div>
        </div>
      </div>
      <Notifications notifications={notifications} anchorEl={anchorEl} handleClose={handleClose} />
    </>
  )
}