import React from 'react'
import {FormControl, IconButton, OutlinedInput } from '@mui/material'

import Footer from '../../components/Footer'
import { Icons } from '../../components/Icons'
import { CardPortraitPure } from '../../components/CardPortraitPure'
import iconSearch from '../../assets/icons/search.svg'

export default function SearchContainer(props) {
  const {
    cardImageSrc,
    mergedShows,
    searchParams,
    setSearchParams
  } = props;

  return (
    <main className='appWrap isCategories'>
      <div className='mainWidth noHeroWrap'>
        <section className='rowXxl'>
          <header className='rowXl flexHorizontal' style={{paddingTop: 20}}>
            <h2>Buscador</h2>
            <div className='searchBar'>
            <FormControl variant='outlined'>
              <OutlinedInput
                value={searchParams.get("filter") || ""}
                aria-describedby='buscador'
                inputProps={{
                  'aria-label': 'Buscador',
                }}
                autoFocus
                onChange={(event) => {
                  let filter = event.target.value;
                  if (filter) {
                    setSearchParams({ filter });
                  } else {
                    setSearchParams({});
                  }
                }}
              />
              <IconButton type='submit' className='searchIcon'><Icons path={iconSearch} /></IconButton>
            </FormControl>
          </div>
          </header>
          
          <ul className='grid grid-8-cols'>
            {mergedShows
              .filter((mergedShows) => {
                let filter = searchParams.get("filter");
                if (!filter) return true;
                let name = mergedShows.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                return name.includes(filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
              })
              .map((serie) => (
                <li key={serie._id} className="articleListItem">
                  <CardPortraitPure
                    content={serie}
                    isUnlocked={true}
                    cardImageSrc={cardImageSrc}
                  />
                </li>
              ))}
          </ul>
        </section>
      </div>
      <Footer />
    </main>
  )
}
