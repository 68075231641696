import { handleActions } from 'redux-actions';

import {
  GET_CONTENT_COLLECTION_START,
  GET_CONTENT_COLLECTION_SUCCESS,
  GET_CONTENT_COLLECTION_ERROR,
  GET_CONTENT_COLLECTION_FINALLY,
} from '../../redux/actions';

const initialState = {
  data: {},
  error: null,
  isLoading: true,
};

export default handleActions({
  [GET_CONTENT_COLLECTION_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [GET_CONTENT_COLLECTION_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
  }),
  [GET_CONTENT_COLLECTION_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [GET_CONTENT_COLLECTION_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
}, initialState);