import React, { useRef } from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { Icons } from '../../components/Icons'
import '../../onBoarding.scss'
import imgLogo from '../../assets/icons/megaGo.svg'
import iconCheck from '../../assets/icons/check.svg'
import iconUnCheck from '../../assets/icons/close.svg'
import iconArrowDown from '../../assets/icons/arrowDown.svg'
import imgNext from '../../assets/icons/next.svg'
import Footer from '../../components/Footer'

const iconInclude = <Icons path={iconCheck} className='iconInclude' />;
const iconExclude = <Icons path={iconUnCheck} className='iconExclude' />;

// Listado de videos desplegados al azar
const heroVideosId = [
  '672cf069fdabb2c04238d8e8', //Los Casablanca
  '6719631607a7b0e06ddcc8d5', //Amores de mercado
  '67d1a37c6f0589ee41cb3ede', //Jardín de Olivia
]
const randomVideo = heroVideosId[Math.floor(Math.random() * heroVideosId.length)];

const appColor = 'baseBlue'

// Colores según video de serie
// let appColor;
// if (randomVideo === '672cf069fdabb2c04238d8e8') {
//   appColor = 'baseBlue'
// } else if (randomVideo === '6719631607a7b0e06ddcc8d5') {
//   appColor = 'baseBlue'
// } else if (randomVideo === '67a22f20dc8b2d37e1b2314a') {
//   appColor = 'baseBlue'
// }

// Listado de preguntas y respuestas
const questionList = [
  {
    "title": "¿Qué incluye el plan Full de Mega GO?",
    "response": "Puedes disfrutar de todos los beneficios del plan básico más las señales en vivo de Mega 2 y ETC, capítulos de teleseries nacionales, series y programas. Además, puedes ver 24 hrs. antes los capítulos de tus teleseries favoritas."
  },
  {
    "title": "¿Cómo puedo pagar Mega GO?",
    "response": "Puedes pagar con crédito, débito y prepago por medio de Flow."
  },
  {
    "title": "¿Dónde está disponible Mega GO?",
    "response": "Actualmente Mega GO sólo está disponible en Chile."
  },
  {
    "title": "¿Qué dispositivos son compatibles con Mega GO?",
    "response": <>Navegadores web, dipositivos móviles, tabletas y televisores. Para ver información detallada ingresa a nuestro <a href='https://megago.cl/ayuda/article/dispositivos-compatibles-con-mega-go' target='_blank' className='link' rel="noreferrer">Centro de Ayuda.</a></>
  },
]

export default function OnboardingContainer(props) {
  const {
    isAuthenticated,
    isFreePlan,
    isIOS,
    isMobile,
    keycloak,
    onboardingPlans,
  } = props

  const isActivePartner = false;
  const navigate = useNavigate();
  const sectionTwo = useRef();

  return (
    <div className={`onboardingContainer ${appColor}`}>
      {/* <div className='promoCode'>
        <img src="" alt="promo" />
        <div>
          <h2></h2>
          <p></p>
        </div>
      </div> */}
      <div className='nav'>
        <Icons path={imgLogo} className='navLogo' />
        <div className='right'>
          <Button
            className=''
            variant='outlined'
            onClick={() =>
              isMobile
                ? isAuthenticated
                  ? navigate('/cuenta')
                  : keycloak.login()
                : navigate('/directing')
            }
          >
            {isAuthenticated ? 'Mi cuenta' : 'Iniciar sesión'}
          </Button>
          {isMobile && !isAuthenticated && isActivePartner &&
            <>
              <span></span>
              <Button
                className=''
                variant='outlined'
                onClick={() => console.log("login proveedor")}
              >
                Iniciar sesión con proveedor
              </Button>
            </>
          }
        </div>
      </div>
      {/* Hero bg */}
      <div className='videoBg videoBgNoBorder'>
        <iframe
          src={`https://mdstrm.com/embed/${randomVideo}?controls=false&volume=0&autoplay=true&loop=true`}
          width='640'
          height='360'
          allow='autoplay; fullscreen; encrypted-media'
          frameBorder='0'
          allowscriptaccess='always'
          scrolling='no'
          title='video background'>
        </iframe>
      </div>
      <div className='sectionListWrap'>
        <div className='sectionBg'></div>
        <section className='fullHSection heroSection'>
          <header>
            <Icons path={imgLogo} className='heroLogo' />
            {!isFreePlan
              ? (
                <>
                  <h2>Descarga la app</h2>
                  <p>Y disfruta tus series antes de que salgan al aire.</p>
                </>
              )
              : (
                <>
                  <h2>Disfruta de tus teleseries favoritas,</h2>
                  <p className='planAnual'>de tus programas preferidos y de eventos exclusivos cuando y donde quieras.</p>
                </>
              )
            }
            <div className='obIntroButtonsWrap'>
              {isMobile && !isFreePlan
                ? isIOS
                  ? (
                    <a href='https://apps.apple.com/cl/app/mega-go/id1571743848' rel='noreferrer'>
                      <img
                        alt='Descárgalo en el App Store'
                        src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/app-store-badge.png'
                        style={{ width: '80%' }}
                      />
                    </a>
                  ) : (
                    <a href='https://play.google.com/store/apps/details?id=cl.megamedia.megago&ah=1BBIBAysiNcshGHuokWDJq1-bXk' rel='noreferrer'>
                      <img
                        alt='Disponible en Google Play'
                        src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/google-play-badge.png'
                        style={{ width: '80%' }}
                      />
                    </a>
                  )
                : (
                  <Button
                    className='introButton yellow'
                    variant='contained'
                    endIcon={<Icons path={imgNext} />}
                    onClick={() => isMobile && isAuthenticated
                      ? navigate('/planes')
                      : window.location.replace('https://sso.mega.cl/auth/realms/megamedia/protocol/openid-connect/registrations?client_id=megago-web&response_type=code&scope=openid&email&redirect_uri=https://megago.cl/&kc_locale=cl')
                    }
                  >
                    {isMobile && isAuthenticated ? 'Selecciona un plan' : 'Prueba 7 días gratis'}
                  </Button>
                )
              }
              {/* <span className='disclaimer'>
                <span>
                  *Antes $, ahora $. Promoción válida hasta
                  <br />
                  el de  de . Disponible sólo para Chile.
                </span>
              </span> */}
              <span className='difLine'>o</span>
              <Button
                className='introButton'
                variant='outlined'
                onClick={() => isMobile
                  ? isAuthenticated
                    ? navigate('/cuenta')
                    : keycloak.login()
                  : navigate('/directing')
                }
              >
                {isMobile && isAuthenticated ? 'Ingresar a mi cuenta' : 'Iniciar sesión'}
              </Button>
              {isActivePartner &&
                <Button
                  className=''
                  variant='outlined'
                  onClick={() => console.log("login provider")}
                >
                  Iniciar sesión con proveedor
                </Button>
              }
            </div>
          </header>
          <Button
            className='moreContentButton'
            variant='outlined'
            endIcon={<Icons path={iconArrowDown} />}
            onClick={() => sectionTwo.current.scrollIntoView({ behavior: "smooth" })}
          >
            Ver planes
          </Button>
        </section>
        <section className='sectionRow' ref={sectionTwo}>
          <header className='sectionHeader'>
            <span>Elige tu plan</span>
            <h2>Prueba gratis durante 7 días.</h2>
          </header>
          <div className='tableWrap obMainWidth'>
            <div className='tableContainer rowL'>
              <table
                className={onboardingPlans
                  .filter(plan => plan.id === process.env.REACT_APP_MS_PLAN_FULL_ANUAL && plan.show_on_plans).length > 0
                  ? 'showAnualPlan'
                  : ''
                }
              >
                <thead>
                  <tr>
                    <td></td>
                    <td className='planPrice'>
                      Plan <br />{
                        onboardingPlans
                          .filter(plan => plan.id === process.env.REACT_APP_MS_PLAN_BASICO)[0]?.shortName || ''
                      }
                    </td>
                    <td className='planPrice'>
                      Plan <br />{
                        onboardingPlans
                          .filter(plan => plan.id === process.env.REACT_APP_MS_PLAN_FULL)[0]?.shortName || ''
                      }
                      <p>${onboardingPlans[1]?.amount.toLocaleString('de') || '--'}
                        <span> al mes</span>
                      </p>
                    </td>
                    <td className='planPrice'>
                      <div className='cardPromo'>
                        ¡Ahorra 4 meses!
                      </div>
                      Plan <br />{
                        onboardingPlans
                          .filter(plan => plan.id === process.env.REACT_APP_MS_PLAN_FULL_ANUAL)[0]?.shortName || ''
                      }
                      <p>${onboardingPlans[2]?.amount.toLocaleString('de') || '--'}
                        <span> al año</span>
                      </p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hasta 5 dispositivos conectados al mismo tiempo</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td>Señales en vivo: Mega, Meganoticias y Megatiempo</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td>Radios: Carolina TV, Infinita, Romántica y FM Tiempo</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td>Documentales</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td>Señales en vivo:  Mega 2 y ETC</td>
                    <td>{iconExclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td>Eventos deportivos</td>
                    <td>{iconExclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td>Capítulos de teleseries nacionales, series y programas</td>
                    <td>{iconExclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td><b>Capítulos sin publicidad</b></td>
                    <td>{iconExclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td><b>¡Preestrenos antes que la tv!</b></td>
                    <td>{iconExclude}</td>
                    <td>{iconInclude}</td>
                    <td>{iconInclude}</td>
                  </tr>
                  <tr>
                    <td></td>
                    {isFreePlan && !isAuthenticated && !isMobile &&
                      <td>
                        <Button
                          variant='outlined'
                          onClick={() => isMobile && isAuthenticated
                            ? navigate('/planes')
                            : window.location.replace('https://sso.mega.cl/auth/realms/megamedia/protocol/openid-connect/registrations?client_id=megago-web&response_type=code&scope=openid&email&redirect_uri=https://megago.cl/&kc_locale=cl')
                          }
                        >
                          Comenzar
                        </Button>
                      </td>
                    }
                    <td colSpan="2">
                      {isMobile && !isFreePlan
                        ? isIOS
                          ? (
                            <a href='https://apps.apple.com/cl/app/mega-go/id1571743848'>
                              <br />
                              Tienes un plan activo, descarga y disfruta de Mega GO:
                              <img
                                alt='Descárgalo en el App Store'
                                src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/app-store-badge.png'
                                style={{ width: '100%' }}
                              />
                            </a>
                          ) : (
                            <a href='https://play.google.com/store/apps/details?id=cl.megamedia.megago&ah=1BBIBAysiNcshGHuokWDJq1-bXk'>
                              <br />
                              Tienes un plan activo, descarga y disfruta de Mega GO:
                              <img
                                alt='Disponible en Google Play'
                                src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/google-play-badge.png'
                                style={{ width: '100%' }}
                              />
                            </a>
                          )
                        : (
                          <Button
                            className='yellow'
                            variant='contained'
                            endIcon={<Icons path={imgNext} />}
                            onClick={() => isMobile && isAuthenticated
                              ? navigate('/planes')
                              : window.location.replace('https://sso.mega.cl/auth/realms/megamedia/protocol/openid-connect/registrations?client_id=megago-web&response_type=code&scope=openid&email&redirect_uri=https://megago.cl/&kc_locale=cl')
                            }
                          >
                            {/* Prueba ahora */}
                            Prueba 7 días gratis
                          </Button>
                        )}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='obMainWidth'>
              <div className='tableContainer tableBnr'>
                <p>
                  <span>Si eres <b>cliente televisión</b></span>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/66a944d1804ee20012b1c95f' alt='Logo VTR' className='vtr' />
                  <span>o</span><img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/66a944c1804ee20012b1c949' alt='Logo Claro' className='claro' />
                  <span>tienes <b>Mega GO</b> incluido en tu pack</span>
                </p>
                <div>
                {!isAuthenticated &&
                  <Button
                    variant='outlined'
                    onClick={() => keycloak.login()}
                  >
                    ¡Ingresa ahora!
                  </Button>
                }
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='sectionRow'>
          <header className='sectionHeader'>
            <span>Series originales y estrenos exclusivos</span>
            <h2>Ve capítulos de tus series cuando y donde quieras.</h2>
          </header>
          <div className='obMainWidth'>
            <ul className='programList'>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/672ce782ded2c400126e3e2a' alt='Los Casablanca' className='card' />
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/672d0a66d5d43e001273e028' alt='Los Casablanca' className='icon' />
                </div>
              </li>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/671bba9570d23c00124f2291' alt='Nuevo Amores de Mercado' className='card' />
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/673758cc9f606e0013f5af04' alt='Nuevo Amores de Mercado' className='icon' />
                </div>
              </li>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67225ceeded2c40012600e2f' alt='I love Robin' className='card' />
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/672e939912dc370012ebfabd' alt='I love Robin' className='icon' />
                  <p className='cardDisclaimer'>*Exclusivo clientes televisión<br />VTR y Claro</p>
                </div>
              </li>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67ae3dfd5b626d0012a6ff3e' alt='El Jardín de Olivia' className='card' />
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67d341f335d6930012ad207e' alt='Juego de Ilusiones' className='icon' />
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className='sectionRow'>
          <header className='sectionHeader'>
            <span>Eventos y Programas</span>
            <h2>Bienvenido Viña 2025.</h2>
          </header>
          <div className='obMainWidth'>
            <ul className='programList'>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67ae4aadd792ce001247057f' alt='Gala' className='card' />
                  {/* <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67815b8b4c7dc40013d434f0' alt='Gala' className='icon' /> */}
                </div>
              </li>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67ae481756223100120948d9' alt='Con Gusto a Viña' className='card' />
                  {/* <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/678527cd4c7dc40013d909e3' alt='Con Gusto a Viña' className='icon' /> */}
                </div>
              </li>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67ae4bbd5b626d0012a70ad1' alt='Viva Viña' className='card' />
                  {/* <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/673b6670d70eab0012f48ccf' alt='Viva Viña' className='icon' /> */}
                </div>
              </li>
              <li>
                <div className='programCard'>
                  <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/67782433ba49d500110d5106' alt='Sangre, Sudor y Gala' className='card' />
                  {/* <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/678527cd4c7dc40013d909e3' alt='Sangre, Sudor y Gala' className='icon' /> */}
                </div>
              </li>
            </ul>
          </div>
          {/* <div className='obMainWidth'>
            <ul className='programList eventList'>
              <li>
                <div className='eventCard'>
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/ob-deportes-campeonato-itau.jpg' alt='Campeonato Itau' className='card' />
                  <div className='eventText'>
                    <h4>Campeonato <br /> Nacional Itaú</h4>
                    <p>Todas las fechas disfrutarás un partido por Mega 2 y Mega GO.</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='eventCard'>
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/ob-deportes-campeonato-brasileirao.jpg' alt='Campeonato Brasileirao' className='card' />
                  <div className='eventText'>
                    <h4>Campeonato brasileño</h4>
                    <p>Sigue a todos los equipos del torneo más importante de Brasil, el campeonato Brasileirão - Série A Betano 2024, en exclusiva por Mega GO.</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='eventCard'>
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/ob-deportes-brasil-bolivia.jpg' alt='Brasil y Bolivia' className='card' />
                  <div className='eventText'>
                    <h4>Brasil y Bolivia <br /> rumbo al mundial</h4>
                    <p>En exclusiva, TODOS sus partidos clasificatorios y amistosos como local rumbo al mundial FIFA 2026. <br /><b>¡Incluye Brasil vs Chile y<br /> Bolivia vs Chile!</b></p>
                  </div>
                </div>
              </li>
              <li>
                <div className='eventCard'>
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/ob-deportes-eventos.jpg' alt='Eventos' className='card' />
                  <div className='eventText'>
                    <h4>Eventos en Mega GO</h4>
                    <p>Múltiples eventos deportivos que Mega GO llevará a todas tus pantallas.</p>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
        </section>
        <section className='sectionRow'>
          <div className='obMainWidth'>
            <div className='devicesComposition'>
              <img src='https://megago.cdn.mdstrm.com/ott/api/v2/screen/images/6758a877d70eab00122921ed' alt='Smart TV, Celular y Computador' />
              <header className='sectionHeader'>
                <span>Cuando y donde quieras.</span>
                <h2>Activa tu Smart TV o dispositivo favorito y disfruta de Mega GO.</h2>
                <div className='osList'>
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/badge-android.png' alt='Disponible en Google Play' />
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/badge-apple.png' alt='Disponible en App Store' />
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/badge-lg.png' alt='Disponible en televisores LG' />
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/badge-samsung.png' alt='Disponible en televisores Samsung' />
                  <img src='https://images2-mega.cdn.mdstrm.com/ott/assets-img/badge-androidtv.png' alt='Disponible en televisores con sistema Android TV' />
                </div>
              </header>
            </div>
          </div>
        </section>
        <section className='sectionRow'>
          <header className='sectionHeader'>
            <span>¿Tienes alguna duda?</span>
            <h2>Revisa aquí preguntas frecuentes.</h2>
          </header>
          <div className='obMainWidth'>
            <div className='questionList'>
              {questionList.map((question, i) => (
                <Accordion key={i + "_questions"}>
                  <AccordionSummary
                    expandIcon={<Icons path={iconArrowDown} />}
                  >
                    <h3>{question.title}</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{question.response}</p>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}