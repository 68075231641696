import React, { useState } from 'react'
import { Button, IconButton, Fade } from '@mui/material'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Icons } from './Icons'
import iconPlayOutline from '../assets/icons/playOutline.svg'
import iconStar from '../assets/icons/star.svg'
import iconStarOutline from '../assets/icons/starOutline.svg'
import iconThumbUp from '../assets/icons/thumbUp.svg'
import iconThumbUpOutline from '../assets/icons/thumbUpOutline.svg'
import iconThumbDown from '../assets/icons/thumbDown.svg'
import iconThumbDownOutline from '../assets/icons/thumbDownOutline.svg'
import LockIcon from '../assets/icons/candado.svg'

export const CardPortrait = (props) => {
  const {
    cardImageSrc,
    cardIndex,
    content,
    handleOnChangePreferences,
    handleOnClickWatchNow,
    preference,
    isUnlocked,
    listStyle,
    listType,
  } = props

  const [isVideoShown, setIsVideoShown] = useState(false)

  const { _id, vodPreview, title, type } = content

  let timeoutId = null

  const handleMouseHover = () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => { setIsVideoShown(true) }, 1000)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutId)
    setIsVideoShown(false)
  }

  let link
  switch (type) {
    case 'Serie':
      link = `/serie/${_id}`
      break;
    case 'Live':
      link = `/player/live/${_id}`
      break;
    case 'Event':
      link = `/evento/${_id}`
      break;
    case 'Vod':
      link = `/player/vod/${_id}`
      break;
    case 'ContentCollection':
      link = `/collection/${_id}`
      break;
    default:
      link = `/inicio`
      break;
  }

  return (
    <>
      {listStyle === 'numbered' && <span className='numberedNumber'>{cardIndex}</span>}
      <article
        className={`articleItemBox withContextSerie ${listStyle === 'numbered' && 'numbered'}`}
        onMouseEnter={() => handleMouseHover()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <Link to={!isUnlocked ? '/planes' : link}>
          <figure className='ratioImgBox ratioPortrait'>
            {!isUnlocked && <Icons path={LockIcon} className='lockIcon' />}
            {type === "Live" && <span className='liveTag'>En vivo</span>}
            <img
              src={cardImageSrc(content, listStyle, listType )}
              alt={title}
            />
            {!isEmpty(vodPreview) && isVideoShown && (
              <Fade in={isVideoShown} timeout={600} style={{ transitionDelay: '500ms' }} >
                <video id={`video_cardPortrait_${vodPreview.idMedia}`} autoPlay preload='auto' loop='loop' muted>
                  <source src={`https://mdstrm.com/video/${vodPreview.idMedia}.mp4`} type='video/mp4' />
                </video>
              </Fade>
            )}
          </figure>
        </Link>
        {type !== 'Live' && type !== 'ContentCollection' &&
          <header>
            <Link to={link}>
              <h3>{title}</h3>
            </Link>
            <div className='buttonGroup'>
              {type !== 'Event' &&
                <Button
                  onClick={() => handleOnClickWatchNow(content)}
                  variant='outlined'
                  startIcon={<Icons path={iconPlayOutline} />}
                >
                  Ver
                </Button>
              }
              {type !== 'Vod' &&
                <>
                  <IconButton
                    aria-label='mi lista'
                    color='primary'
                    onClick={() => handleOnChangePreferences({ id: _id, favorite: preference && preference.favorite ? !preference.favorite : true })}
                    className={preference && preference.favorite && 'checked'}
                  >
                    <Icons path={preference && preference.favorite ? iconStar : iconStarOutline} />
                  </IconButton>
                  <IconButton
                    aria-label='me gusta'
                    color='primary'
                    onClick={() => handleOnChangePreferences({ id: _id, thumb: preference && preference.thumb === 'UP' ? '' : 'UP' })}
                  >
                    <Icons path={preference && preference.thumb === 'UP' ? iconThumbUp : iconThumbUpOutline} />
                  </IconButton>
                  <IconButton
                    aria-label='no me gusta'
                    color='primary'
                    onClick={() => handleOnChangePreferences({ id: _id, thumb: preference && preference.thumb === 'DOWN' ? '' : 'DOWN' })}
                  >
                    <Icons path={preference && preference.thumb === 'DOWN' ? iconThumbDown : iconThumbDownOutline} />
                  </IconButton>
                </>
              }
            </div>
          </header>
        }
      </article>
    </>
  )
}