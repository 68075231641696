import { all } from 'redux-saga/effects'

import AuthActionsWatcher from '../components/auth/auth.saga'
import ContentCollectionActionsWatcher from '../components/contentCollection/contentCollection.saga'
import ListsActionsWatcher from '../components/home/lists/lists.saga'
import NewsActionsWatcher from '../components/home/news/news.saga'
import ContinueWatchingActionsWatcher from '../components/home/continueWatching/continueWatching.saga'
import SerieActionsWatcher from '../components/serie/serie/serie.saga'
import EventActionsWatcher from '../components/event/event/event.saga'
import GetMediaActionWatcher from '../components/player/media/media.saga'
import GetProfilesActionWatcher from '../components/profiles/profiles/profiles.saga'
import GetCategoriesActionWatcher from '../components/categories/categories/categories.saga'
import GetSubscriptionActionWatcher from '../components/subscription/subscription.saga'
import NotificationsActionWatcher from '../components/notifications/notifications.saga'
import PlansActionWatcher from '../components/plans/plans/plans.saga'

export default function* rootSaga() {
  yield all([
    AuthActionsWatcher(),
    ContentCollectionActionsWatcher(),
    ListsActionsWatcher(),
    NewsActionsWatcher(),
    ContinueWatchingActionsWatcher(),
    SerieActionsWatcher(),
    EventActionsWatcher(),
    GetMediaActionWatcher(),
    GetProfilesActionWatcher(),
    GetCategoriesActionWatcher(),
    GetSubscriptionActionWatcher(),
    NotificationsActionWatcher(),
    PlansActionWatcher()
  ])
}