import React from 'react'
import Slider from 'react-slick'
import { intersection, isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import { CardInfo } from '../../components/CardInfo'
import { CardThumb } from '../../components/CardThumb'
import { CardPortrait } from '../../components/CardPortrait'
import ContentCollectionHeroContainer from './ContentCollectionHeroContainer'

export default function ContentCollectionContainer(props) {
  const {
    cardImageSrc,
    handleOnChangePreferences,
    handleOnClickWatchNow,
    contentCollection,
    contentCollection: {
      lists = []
    },
    preferencesKeyedById,
    // sliderNewsSettings,
    sliderSettings,
    slidesToShow,
    slidesToScroll,
    sliderRowStyle,
    subscriptionsPlanIds,
    useImageCDN,
    // sponsored,
  } = props

  return (
    <main
      className={
        `appWrap fixedHero${lists?.filter(
          list => list.type === 'Highlighted' || list.isHighlighted)[0]?.content?.length > 1
          ? ' fixed-hero-container'
          : ''
        }`
      }
    >
      <ContentCollectionHeroContainer
        contentCollection={contentCollection}
        handleOnClickWatchNow={handleOnClickWatchNow}
        useImageCDN={useImageCDN}
        //sponsored={sponsored}
      />
      {lists.map(row => (
        row.style === 'tiles' ?
          <section key={row._id} className='rowXxl horizontalRow listTilesWrap'>
            <div className='mainWidth'>
              <ul className='grid'>
                {(row.content).map(content => (
                  <li key={content._id} className='articleListItem'>
                    <CardThumb
                      cardType={content.type}
                      contentId={content._id}
                      image={!isEmpty(content.imagesSite.thumbnail)
                        ? useImageCDN
                          ? content.imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                          : content.imagesSite.thumbnail
                        : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
                      }
                      isUnlocked={content.type === 'Live' ? !isEmpty(intersection(subscriptionsPlanIds, content.planIds || [])) : true}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </section>
        : row.content.length > 0 &&
          <section key={row._id} className={`rowXxl horizontalRow ${sliderRowStyle(row.style)}`}>
            <header className='rowS mainWidth'>
              <h2>{row.title}</h2>
            </header>
            <Slider {...sliderSettings} slidesToShow={slidesToShow(row.style)} slidesToScroll={slidesToScroll(row.style)}>
              {(row.content).map((content, index) => (
                <div key={content._id} className='articleListItem'>
                  {row.type === 'Vod' ?
                    <CardInfo
                      vod={content}
                      isHome
                      isUnlocked={!isEmpty(intersection(subscriptionsPlanIds, content.serie?.planIds || []))}
                      pay={content.pay}
                      showSeason={content.serie?.seasons?.length > 1 || false}
                      useImageCDN={useImageCDN}
                    />
                    :
                    <CardPortrait
                      cardImageSrc={cardImageSrc}
                      content={content}
                      handleOnChangePreferences={handleOnChangePreferences}
                      handleOnClickWatchNow={handleOnClickWatchNow}
                      preference={preferencesKeyedById[content._id]}
                      cardIndex={index + 1}
                      isUnlocked={content.type === 'Live' ? !isEmpty(intersection(subscriptionsPlanIds, content.planIds || [])) : true}
                      listStyle={row.style}
                      listType={row.type}
                    />
                  }
                </div>
              ))}
              {row.content.length > 1 &&
                <div key='last-item-placeholder' className='articleListItem'></div>
              }
            </Slider>
          </section>
      ))}
      <Footer />
    </main>
  )
}