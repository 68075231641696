import React from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import moment from 'moment'
import 'moment/locale/es-mx'

import { Icons } from './Icons'
import LockIcon from '../assets/icons/candado.svg'

moment.locale('es-mx')

export const CardInfoEvent = (props) => {
  const {
    item: { title, imagesSite, description, pay, dateEnd, dateStart },
    isUnlocked,
    link,
    useImageCDN,
    currTagName,
    eventTagColor,
    cardDateStart,
    cardHourStart,
    cardHourEnd
  } = props;

  const eventTagClass = !isUnlocked && pay ? 'eventTag free' : 'eventTag'

  return (
    <Link to={link} style={link === null ? {pointerEvents: 'none'} : {pointerEvents: 'all'}}>
      <article className='articleItemBox whitVisibleTitle'>
        <div className='calendarCardDate'>
          <h5>{cardDateStart}</h5>
          <p>{cardHourStart} - {cardHourEnd}</p>
        </div>
        <div className='calendarImgWrap'>
          <figure className='ratioImgBox ratioThumb'>
            <span
              className={eventTagClass}
              data-tagname={eventTagColor(dateStart, dateEnd)}
            >
              {currTagName(dateStart, dateEnd)}
            </span>
            {!isUnlocked && pay && <Icons path={LockIcon} className='lockIcon' />}
            <img
              src={!isEmpty(imagesSite.thumbnail)
                ? useImageCDN
                  ? imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                  : imagesSite.thumbnail
                : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
              }
              alt=''
            />
          </figure>
        </div>
        <header>
          <h3>{title}</h3>
          <p>{description}</p>
        </header>
      </article>
    </Link>
  )
}