import React from 'react'
import { isEmpty } from 'lodash'
import { useScrollPosition } from '../../hooks/useScrollPosition'

export default function ContentCollectionHeroContainer(props) {
  const {
    contentCollection,
    useImageCDN,
    // sponsored
  } = props

  const scrollPosition = useScrollPosition()

  // Función para dejar fixed header y cambiar opacidad del hero
  const normalizeBetweenTwoRanges = (val, minVal, maxVal, newMin, newMax) => {
    return newMin + (val - minVal) * (newMax - newMin) / (maxVal - minVal)
  }

  const newOpacity = normalizeBetweenTwoRanges(scrollPosition, 0, 200, 1, 0)

  return (
    <div className='heroContentWrap' style={{ opacity: newOpacity }}>
      <div key={contentCollection._id}>
        <article
        // style={contentCollection._id === sponsored._id ? { backgroundColor: sponsored.bgColor } : {}}
        // className={classNames({
        //   'sponsoredItem': contentCollection._id === sponsored._id
        // })}
        >
          <div className='itemContainer'>
            <div className='heroHeader collectionHero'>
              <div className='mainWidth'>
                <figure className='heroFig'>
                  <img
                    src={!isEmpty(contentCollection.imagesSite.logo)
                      ? useImageCDN
                        ? contentCollection.imagesSite.logo.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                        : contentCollection.imagesSite.logo
                      : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-logo.png'
                    }
                    alt={contentCollection.title}
                    style={{ position: 'relative' }}
                  />
                </figure>
              </div>
            </div>
            <div className='heroImgBox'>
              <span className='shadowRight'></span>
              <span className='shadowLeft'></span>
              <span className='shadowBottom'></span>
              <figure className='ratioImgBox ratioHero'>
                <img
                  src={!isEmpty(contentCollection.imagesSite.hero)
                    ? useImageCDN
                      ? contentCollection.imagesSite.hero.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                      : contentCollection.imagesSite.hero
                    : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-hero.jpg'
                  }
                  alt={contentCollection?.title || ''}
                />
                {/* {contentCollection?.vodTrailer?.idMedia &&
                  <video id={`video_homeHero_${contentCollection._id}`} controls={false} autoPlay preload='auto' loop='loop' muted>
                    {document.body.style.cursor = 'auto'}
                    <source src={`https://mdstrm.com/video/${contentCollection.vodTrailer.idMedia}.mp4`} type='video/mp4' />
                  </video>
                } */}
              </figure>
            </div>
          </div>
          <span className='shadowBottom'></span>
        </article>
      </div>
    </div>
  )
}