import React from 'react'
import { Link } from 'react-router-dom'
import { Icons } from './Icons'
import LockIcon from '../assets/icons/candado.svg'

export const CardPortraitPure = (props) => {
  const {
    cardImageSrc,
    content,
    isUnlocked,
  } = props

  const { _id, title, type } = content

  let link
  switch (type) {
    case 'Serie':
      link = `/serie/${_id}`
      break;
    case 'Live':
      link = `/player/live/${_id}`
      break;
    case 'Event':
      link = `/evento/${_id}`
      break;
    case 'Vod':
      link = `/player/vod/${_id}`
      break;
    case 'ContentCollection':
      link = `/collection/${_id}`
      break;
    default:
      link = `/inicio`
      break;
  }

  return (
    <>
      <article
        className='articleItemBox withContextSerie'
      >
        <Link to={!isUnlocked ? '/planes' : link}>
          <figure className='ratioImgBox ratioPortrait'>
            {!isUnlocked && <Icons path={LockIcon} className='lockIcon' />}
            {type === "Live" && <span className='liveTag'>En vivo</span>}
            <img
              src={cardImageSrc(content)}
              alt={title}
              loading='lazy'
            />
          </figure>
        </Link>
      </article>
    </>
  )
}