import React from 'react'
import { Link } from 'react-router-dom'

import { Icons } from './Icons'
import LockIcon from '../assets/icons/candado.svg'

export const CardThumb = (props) => {
  const {
    contentId,
    cardType,
    image,
    isUnlocked,
  } = props

  let link;
  switch (cardType) {
    case 'Serie':
      link = `/serie/${contentId}`
      break;
    case 'Live':
      link = `/player/live/${contentId}`
      break;
    case 'Event':
      link = `/evento/${contentId}`
      break;
    case 'Vod':
      link = `/player/vod/${contentId}`
      break;
    case 'ContentCollection':
      link = `/collection/${contentId}`
      break;
    default:
      link = `/inicio`
      break;
  }

  return (
    <Link to={!isUnlocked ? '/planes' : link}>
      <article className='articleItemBox'>
        <figure className='ratioImgBox ratioThumb'>
          {!isUnlocked && <Icons path={LockIcon} className='lockIcon' />}
          {cardType === "Live" && <span className='liveTag'>En vivo</span>}
          <img src={image} alt='' />
        </figure>
      </article>
    </Link>
  )
}