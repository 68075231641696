import React from 'react'
import { Select, MenuItem, FormControl } from '@mui/material'

import Footer from '../../components/Footer'
import { Icons } from '../../components/Icons'
import iconArrowDown from '../../assets/icons/arrowDown.svg'
import { CardPortrait } from '../../components/CardPortrait'

export default function CategoriesContainer(props) {
  const {
    cardImageSrc,
    categories,
    categoryIdSelected,
    handleOnChangeCategory,
    handleOnChangePreferences,
    handleOnClickWatchNow,
    preferencesKeyedById,
    seriesFiltered,
    useImageCDN
  } = props;

  return (
    <main className='appWrap isCategories'>
      <div className='mainWidth noHeroWrap'>
        <section className='rowXxl'>
          <header className='rowS flexHorizontal'>
            <h2>Categorías</h2>
            <FormControl>
              <Select
                labelId='season-select'
                id='season-select'
                value={categoryIdSelected}
                onChange={handleOnChangeCategory}
                defaultValue={categoryIdSelected}
                className='selectBase'
                IconComponent={() => (<Icons path={iconArrowDown} />)}
              >
                {categories.map(category => (
                  <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </header>
          <ul className='grid grid-6-cols'>
            {seriesFiltered.map(serie => (
                <li key={serie._id} className='articleListItem'>
                  <CardPortrait
                    content={serie}
                    handleOnChangePreferences={handleOnChangePreferences}
                    handleOnClickWatchNow={handleOnClickWatchNow}
                    preference={preferencesKeyedById[serie._id]}
                    useImageCDN={useImageCDN}
                    isUnlocked={true}
                    cardImageSrc={cardImageSrc}
                    listStyle={null}
                    listType="vod"
                  />
                </li>
              ))
            }
          </ul>
        </section>
      </div>
      <Footer />
    </main>
  )
}
