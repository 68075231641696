import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
// import { isEmpty } from 'lodash'

import {
  GET_CONTENT_COLLECTION,
  GET_CONTENT_COLLECTION_START,
  GET_CONTENT_COLLECTION_SUCCESS,
  GET_CONTENT_COLLECTION_ERROR,
  GET_CONTENT_COLLECTION_FINALLY,
} from '../../redux/actions'

function* GetContentCollectionAction({ payload: { keycloak, id } }) {
  yield put(GET_CONTENT_COLLECTION_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/content-collections/${id}`,
      headers
    )

    yield put(GET_CONTENT_COLLECTION_SUCCESS(data))
  } catch (error) {
    yield put(GET_CONTENT_COLLECTION_ERROR(error.message))
  } finally {
    yield put(GET_CONTENT_COLLECTION_FINALLY())
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_CONTENT_COLLECTION, GetContentCollectionAction)
}