import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compact, isEmpty, keyBy } from 'lodash'
import { useNavigate } from 'react-router-dom'

import FavoritesContainer from '../../containers/favorites/FavoritesContainer'
import Loading from '../Loading'
import { watchNow } from '../../utils/watchNow'
import { CreateAnchor, DestroyAds } from '../Ads'

import { GET_SERIES, GET_EVENTS, SET_PROFILE_PREFERENCES, GET_CONTINUE_WATCHING } from '../../redux/actions'

export default memo(function FavoritesComponent() {
  const { isAuthenticated, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth)
  const { data: series, isLoading: isLoadingSeries } = useSelector(state => state.series)
  const { data: events, isLoading: isLoadingEvents } = useSelector(state => state.events)
  const { continueWatching, isLoading } = useSelector(state => state.continueWatching)
  const { currentProfile, preferences } = useSelector(state => state.profiles)
  const [favotireSeries, setFavotireSeries] = useState([])
  const { isFreePlan } = useSelector(state => state.subscription)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const limit = 12
  const page = 1

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    isEmpty(series) && dispatch(GET_SERIES({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isEmpty(events) && dispatch(GET_EVENTS({ keycloak, limit, page }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEmpty(preferences) || isEmpty(series)) {
      setFavotireSeries([])
    } else {
      const mergeSeries = isEmpty(events) ? [...series] : [...events, ...series];
      const seriesKeyedById = keyBy(mergeSeries, '_id')
      const favSeries = compact(preferences.map(preference => {
        if (seriesKeyedById[preference.id] && preference.favorite === true) {
          return seriesKeyedById[preference.id]
        } else {
          return null
        }
      }))
      setFavotireSeries(favSeries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series, preferences])

  useEffect(() => {
    dispatch(GET_CONTINUE_WATCHING({
      keycloak, idCurrentProfile: currentProfile.id
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfile.id])

  const preferencesKeyedById = keyBy(preferences, 'id')

  const handleOnChangePreferences = (newPreference) => {
    const newPreferences = isEmpty(preferencesKeyedById[newPreference.id])
      ? {
        id: newPreference.id,
        favorite: false,
        thumb: "",
        ...newPreference
      } : {
        ...preferencesKeyedById[newPreference.id],
        ...newPreference
      }

    dispatch(SET_PROFILE_PREFERENCES({
      keycloak, idSerie: newPreference.id, idCurrentProfile: currentProfile.id, newPreferences
    }))
  }

  const handleOnClickWatchNow = (serie) => {
    if (!isEmpty(serie)) {
      if (!isEmpty(continueWatching)) {
        const continueWatchingFilteredBySerie = continueWatching.filter(vod => vod.idSerie === serie._id)

        if (!isEmpty(continueWatchingFilteredBySerie)) {
          watchNow(navigate, continueWatchingFilteredBySerie, serie)
        } else {
          !isEmpty(serie.seasons[0]?.vods[0])
            ? navigate(`/player/vod/${serie.seasons[0]?.vods[0]._id}`)
            : navigate(`/serie/${serie._id}`)
        }
      } else {
        !isEmpty(serie.seasons[0]?.vods[0])
          ? navigate(`/player/vod/${serie.seasons[0]?.vods[0]._id}`)
          : navigate(`/serie/${serie._id}`)
      }
    }
  }

  // Set image size based on list style
  const cardImageSrc = (item, listStyle, listType) => {
    if (listStyle === "tiles") {
      return !useImageCDN ? item.imagesSite?.thumbnail : item.imagesSite?.thumbnail?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "1column") {
      return !useImageCDN ? item.imagesSite?.oneColumn : item.imagesSite?.oneColumn?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "2column") {
      return !useImageCDN ? item.imagesSite?.twoColumns : item.imagesSite?.twoColumns?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "3column" || listStyle === "4column") {
      return !useImageCDN ? item.imagesSite?.manyColumns : item.imagesSite?.manyColumns?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "vod" || listType === "Vod") {
      return !useImageCDN ? item.serie?.imagesSite?.poster : item.serie?.imagesSite?.poster?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else {
      return !useImageCDN ? item.imagesSite?.poster : item.imagesSite?.poster?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    }
  }

  useEffect(() => {
    document.title = 'Mega GO | Mi Lista'
    return () => {
      document.title = 'Mega GO'
    }
  }, [])

  // Crea y destruye publicidades
  useEffect(() => {
    if (isFreePlan) {
      CreateAnchor('/ott/favoritos/home/', {
        'seccion': 'favoritos',
        'nivel': 'home',
        'nota': '',
        'id_nota': '',
        'tipo': 'otro',
        'keywords': ''
      })
      return () => {
        DestroyAds()
      }
    }
  }, [isFreePlan])

  return (
    isLoading || isLoadingSeries || isLoadingEvents
      ?
      <Loading />
      :
      <FavoritesContainer
        cardImageSrc={cardImageSrc}
        currentProfile={currentProfile}
        handleOnChangePreferences={handleOnChangePreferences}
        handleOnClickWatchNow={handleOnClickWatchNow}
        preferencesKeyedById={preferencesKeyedById}
        series={favotireSeries}
        useImageCDN={useImageCDN}
      />
  )
})