import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { isEmpty, keyBy, sortBy } from 'lodash'

import {
  GET_SERIE,
  GET_SERIE_START,
  GET_SERIE_SUCCESS,
  GET_SERIE_ERROR,
  GET_SERIE_FINALLY,
  GET_SERIES,
  GET_SERIES_START,
  GET_SERIES_SUCCESS,
  GET_SERIES_ERROR,
  GET_SERIES_FINALLY,
  MERGE_CONTINUE_WATCHING_SERIE,
  MERGE_CONTINUE_WATCHING_SERIE_START,
  MERGE_CONTINUE_WATCHING_SERIE_FINALLY,
  GET_WATCH_NOW_SUCCESS,
} from '../../../redux/actions'

function* GetSerieAction({ payload: { keycloak, id } }) {
  yield put(GET_SERIE_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/series/${id}`,
      headers
    )

    yield put(GET_SERIE_SUCCESS(data))
  } catch (error) {
    yield put(GET_SERIE_ERROR(error.message))
  } finally {
    yield put(GET_SERIE_FINALLY())
  }
}

function* GetSeriesAction({ payload: { keycloak } }) {
  yield put(GET_SERIES_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/series`,
      headers
    )

    yield put(GET_SERIES_SUCCESS(data))
  } catch (error) {
    yield put(GET_SERIES_ERROR(error.message))
  } finally {
    yield put(GET_SERIES_FINALLY())
  }
}

function* MergeContinueWatchingSerieAction({ payload: { continueWatching, serie } }) {
  yield put(MERGE_CONTINUE_WATCHING_SERIE_START())

  let newSerie = serie
  let watchNow = {}
  const continueWatchingById = keyBy(continueWatching, '_id')

  var allVods = []
  const newSeasons = serie.seasons.map(season => {
    var vods = []

    season.vods.forEach(vod => {
      const cwSelected = continueWatchingById[vod._id]

      if (!isEmpty(cwSelected)) {
        vods.push({
          ...vod,
          ...cwSelected
        })

        allVods.push({
          ...vod,
          ...cwSelected
        })
      } else {
        vods.push({
          ...vod,
          duration: null,
          position: null
        })

        allVods.push({
          ...vod,
          duration: null,
          position: null
        })
      }
    })

    return {
      ...season,
      vods
    }
  })

  const isNotViewedFiltered = allVods.filter(x => x.viewedLastAt)
  const latestViewed = sortBy(isNotViewedFiltered, 'viewedLastAt').reverse()

  if (isEmpty(latestViewed)) {
    watchNow = !isEmpty(allVods) ? allVods[0] : {}
  } else {
    const lastViewed = latestViewed[0]

    if (lastViewed.isViewed === true) {
      const lastVodIndex = allVods.findIndex(vod => vod._id === lastViewed._id)

      if (lastVodIndex < allVods.length - 1) {
        let aux = 1

        do {
          const nextInAllVods = allVods[lastVodIndex + aux]
          const nextVod = allVods[allVods.length - 1]._id !== nextInAllVods._id
            ? nextInAllVods
            : {}

          if (isEmpty(nextVod)) {
            watchNow = nextInAllVods
          } else if (nextVod.isViewed === true) {
            aux += 1
          } else {
            watchNow = nextVod
          }
        } while (isEmpty(watchNow))
      } else {
        watchNow = allVods[lastVodIndex]
      }
    } else {
      watchNow = lastViewed
    }
  }

  newSerie = {
    ...newSerie,
    seasons: newSeasons
  }

  yield put(GET_WATCH_NOW_SUCCESS(watchNow))
  yield put(GET_SERIE_SUCCESS(newSerie))
  yield put(MERGE_CONTINUE_WATCHING_SERIE_FINALLY())
}

export default function* actionsWatcher() {
  yield takeLatest(GET_SERIE, GetSerieAction)
  yield takeLatest(GET_SERIES, GetSeriesAction)
  yield takeLatest(MERGE_CONTINUE_WATCHING_SERIE, MergeContinueWatchingSerieAction)
}