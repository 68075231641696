import React, { memo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, keyBy } from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
// import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import axios from 'axios'

import ContentCollectionContainer from '../../containers/contentCollection/ContentCollectionContainer'
import Loading from '../Loading'
import { watchNow } from '../../utils/watchNow'
import { Icons } from '../Icons'
import ArrowNext from '../../assets/icons/next.svg'
import ArrowPrev from '../../assets/icons/prev.svg'
// import { CreateAnchor, CreateSponsored, DestroyAds } from '../Ads'

import {
  GET_CONTENT_COLLECTION,
  // GET_NEWS,
  // GET_CONTINUE_WATCHING,
  // GET_NOT_VIEWED_CONTINUE_WATCHING,
  // MERGE_CONTINUE_WATCHING_LISTS,
  SET_PROFILE_PREFERENCES,
  // GET_NOTIFICATIONS,
  // GET_NEWS_FINALLY,
  GET_SERIE_SUCCESS
} from '../../redux/actions'

export default memo(function ContentCollectionComponent() {
  const { isAuthenticated, keycloak, isLoading, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth)
  const { data: contentCollection, isLoading: isLoadingContentCollection } = useSelector(state => state.contentCollection)
  const {
    continueWatching
  } = useSelector(state => state.continueWatching)
  const { currentProfile, preferences } = useSelector(state => state.profiles)
  const { subscriptionsPlanIds } = useSelector(state => state.subscription)
  const { serie: serieFromRedis } = useSelector(state => state.series)
  const [numberOfSlide, setNumberOfSlides] = useState(6)
  // const [sponsored, setSponsored] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  // const getUserCallback = useCallback(async () => {
  //   const db = getFirestore()
  //   const user = await getDoc(doc(db, 'users', keycloak.subject))
  //   return user.exists() ? user.data() : {}
  // }, [keycloak])

  // const setUserCallback = useCallback(async () => {
  //   const db = getFirestore()
  //   await updateDoc(doc(db, 'users', keycloak.subject), { firstTime: false })
  // }, [keycloak])

  const getSerieCallback = useCallback(async (idSerie) => {
    if (!isEmpty(keycloak)) {
      try {
        const headers = {
          headers: { 'authorization': `Bearer ${keycloak.token}` },
          timeout: 10000
        }

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}screen/series/${idSerie}`, headers)

        dispatch(GET_SERIE_SUCCESS(data))

        return data
      } catch (error) {
        return {}
      }
    } else {
      return {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak])

  // useEffect(() => {
  //   if (isAuthenticated && subscriptionsPlanIds.length <= 1) {
  //     getUserCallback()
  //       .then(user => {
  //         if (!isEmpty(user)) {
  //           if (user.firstTime !== false) {
  //             if (plansShowFirstVisit) {
  //               setUserCallback()
  //                 .then(() => {
  //                   navigate('/planes')
  //                 })
  //                 .catch(() => setIsLoadingShowPlans(false))
  //             } else {
  //               setIsLoadingShowPlans(false)
  //             }
  //           } else {
  //             setIsLoadingShowPlans(false)
  //           }
  //         } else {
  //           setIsLoadingShowPlans(false)
  //         }
  //       })
  //       .catch(() => setIsLoadingShowPlans(false))
  //   } else {
  //     setIsLoadingShowPlans(false)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAuthenticated])

  useEffect(() => {
    if (!isEmpty(keycloak)) {
      dispatch(GET_CONTENT_COLLECTION({ keycloak, id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak])

  // useEffect(() => {
  //   isEmpty(notifications) && dispatch(GET_NOTIFICATIONS({ keycloak }))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  //Cambia el tamnaño de los slides según el ancho de la pantalla
  useEffect(() => {
    function setSliderNumbers() {
      if (window.matchMedia("(min-width: 1600px)").matches) {
        setNumberOfSlides(7)
      } else if (window.matchMedia("(min-width: 1300px)").matches) {
        setNumberOfSlides(6)
      } else if (window.matchMedia("(min-width: 1000px)").matches) {
        setNumberOfSlides(5)
      } else if (window.matchMedia("(min-width: 650px)").matches) {
        setNumberOfSlides(4)
      } else {
        setNumberOfSlides(3)
      }
    }
    window.addEventListener("resize", setSliderNumbers)
    setSliderNumbers()
    return () => window.removeEventListener("resize", setSliderNumbers)
  }, [])

  //Función de sliders
  const SliderArrow = (props) => {
    const { className, onClick, iconPath } = props
    return (
      <div className={className} onClick={onClick}>
        <Icons path={iconPath} />
      </div>
    )
  }

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: numberOfSlide + 1,
    slidesToScroll: numberOfSlide,
    lazyLoad: 'ondemand',
    nextArrow: <SliderArrow iconPath={ArrowNext} />,
    prevArrow: <SliderArrow iconPath={ArrowPrev} />
  }

  const slidesToShow = (style) =>{
    switch (style) {
      case '1column':
        return 2
      case '2column':
        return 3
      case '3column':
        return 4
      case '4column':
        return 5
      case 'numbered':
        return 6
      default:
        return 7
    }
  }

  const slidesToScroll = (style) =>{
    switch (style) {
      case '1column':
        return 1
      case '2column':
        return 2
      case '3column':
        return 3
      case '4column':
        return 4
      case 'numbered':
        return 5
      default:
        return 6
    }
  }

  const sliderRowStyle = (style) => {
    switch (style) {
      case 'live':
        return 'listLiveWrap'
      case '1column':
        return 'listOneCardWrap'
      case '2column':
        return 'listTwoCardWrap'
      case '3column':
        return 'listThreeCardWrap'
      case '4column':
        return 'listFourCardWrap'
      default:
        return ''
    }
  }

  // Set image size based on list style
  const cardImageSrc = (item, listStyle, listType) => {
    if (listStyle === "tiles" || listStyle === "live"){
      return !useImageCDN ? item.imagesSite?.thumbnail : item.imagesSite?.thumbnail?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "1column"){
      return !useImageCDN ? item.imagesSite?.oneColumn : item.imagesSite?.oneColumn?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "2column"){
      return !useImageCDN ? item.imagesSite?.twoColumns : item.imagesSite?.twoColumns?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "3column" || listStyle === "4column" ){
      return !useImageCDN ? item.imagesSite?.manyColumns : item.imagesSite?.manyColumns?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else if (listStyle === "vod" || listType === "Vod"){
      return !useImageCDN ? item.serie?.imagesSite?.poster : item.serie?.imagesSite?.poster?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    } else{
      return !useImageCDN ? item.imagesSite?.poster : item.imagesSite?.poster?.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
    }
  }

  const preferencesKeyedById = keyBy(preferences, 'id')

  const handleOnChangePreferences = (newPreference) => {
    const newPreferences = isEmpty(preferencesKeyedById[newPreference.id])
      ? {
        id: newPreference.id,
        favorite: false,
        thumb: '',
        ...newPreference
      } : {
        ...preferencesKeyedById[newPreference.id],
        ...newPreference
      }

    dispatch(SET_PROFILE_PREFERENCES({
      keycloak, idSerie: newPreference.id, idCurrentProfile: currentProfile.id, newPreferences
    }))
  }

  const handleOnClickWatchNow = async (serie) => {
    let watchNowSerie = serieFromRedis

    if (isEmpty(serieFromRedis) || serieFromRedis._id !== serie._id) {
      await getSerieCallback(serie._id)
        .then(data => {
          watchNowSerie = data
        })
        .catch(() => {
          watchNowSerie = {}
        })
    }

    if (!isEmpty(continueWatching)) {
      const continueWatchingFilteredBySerie = continueWatching.filter(vod => vod.idSerie === watchNowSerie._id)

      if (!isEmpty(continueWatchingFilteredBySerie)) {
        watchNow(navigate, continueWatchingFilteredBySerie, watchNowSerie)
      } else {
        (watchNowSerie?.seasons[0]?.vods[0])
          ? navigate(`/player/vod/${watchNowSerie.seasons[0]?.vods[0]?._id}`)
          : navigate(`/serie/${watchNowSerie._id}`)
      }
    } else {
      (watchNowSerie?.seasons[0]?.vods[0])
        ? navigate(`/player/vod/${watchNowSerie.seasons[0]?.vods[0]?._id}`)
        : navigate(`/serie/${watchNowSerie._id}`)
    }
  }

  // Crea y destruye publicidades
  // useEffect(() => {
  //   if (isFreePlan) {
  //     CreateAnchor('/ott/home/home/', {
  //       'seccion': 'portada',
  //       'nivel': 'home',
  //       'nota': '',
  //       'id_nota': '',
  //       'tipo': 'otro',
  //       'keywords': ''
  //     })
  //     CreateSponsored('/ott/home/home/', setSponsored)
  //     return () => {
  //       DestroyAds()
  //     }
  //   }
  // }, [isFreePlan]);

  return (
    (isLoading || isLoadingContentCollection)
      ?
      <Loading />
      :
      <ContentCollectionContainer
        cardImageSrc={cardImageSrc}
        handleOnChangePreferences={handleOnChangePreferences}
        handleOnClickWatchNow={handleOnClickWatchNow}
        contentCollection={contentCollection}
        preferencesKeyedById={preferencesKeyedById}
        // sliderNewsSettings={sliderNewsSettings}
        sliderSettings={sliderSettings}
        subscriptionsPlanIds={subscriptionsPlanIds}
        useImageCDN={useImageCDN}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        sliderRowStyle={sliderRowStyle}
        // sponsored={sponsored}
      />
  )
})