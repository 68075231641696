import React from 'react'
import { isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import { CardPortrait } from '../../components/CardPortrait'

export default function FavoritesContainer(props) {
  const {
    cardImageSrc,
    handleOnChangePreferences,
    handleOnClickWatchNow,
    preferencesKeyedById,
    series,
    useImageCDN
  } = props

  return (
    <main className='appWrap isFavorites'>
      <div className='mainWidth noHeroWrap'>
        <section className='rowXxl'>
          {!isEmpty(series) ?
            <>
              <header className='rowS'>
                <h2>Mi lista</h2>
              </header>
              <ul className='grid grid-5-cols'>
              {series.map(serie => (
                <li key={serie._id} className='articleListItem'>
                  <CardPortrait
                    content={serie}
                    handleOnChangePreferences={handleOnChangePreferences}
                    handleOnClickWatchNow={handleOnClickWatchNow}
                    preference={preferencesKeyedById[serie._id]}
                    useImageCDN={useImageCDN}
                    isUnlocked={true}
                    cardImageSrc={cardImageSrc}
                  />
                </li>
              ))}
              </ul>
            </>
            :
            <div className='favText'>
              <h2>Mi lista</h2>
              <p>Aún no has agregado contenido a tu lista.</p>
            </div>
          }
        </section>
      </div>
      <Footer />
    </main>
  )
}
