import React, {useState, useRef, useEffect, useCallback} from 'react'
import {
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { isEmpty } from 'lodash'
import moment from 'moment'

import Footer from '../../components/Footer'
import { CardInfoEvent } from '../../components/CardInfoEvent'
import { CardInfo } from '../../components/CardInfo'
import { Icons } from '../../components/Icons'
import iconStar from '../../assets/icons/star.svg'
import iconStarOutline from '../../assets/icons/starOutline.svg'
import iconThumbUp from '../../assets/icons/thumbUp.svg'
import iconThumbUpOutline from '../../assets/icons/thumbUpOutline.svg'
import iconThumbDown from '../../assets/icons/thumbDown.svg'
import iconThumbDownOutline from '../../assets/icons/thumbDownOutline.svg'
import iconArrowDown from '../../assets/icons/arrowDown.svg'
import EventHeroContianer from './EventHeroContainer'

// Tabpanels para secciones
function TabPanelCustom(props) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`event-tabpanel-${index}`}
      aria-labelledby={`event-tab-${index}`}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

TabPanelCustom.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function tabsProps(index) {
  return {
    id: `event-tab-${index}`,
    'aria-controls': `event-tabpanel-${index}`,
  }
}

export default function EventContainer(props) {
  const {
    event: {
      imagesSite,
      resume,
      sections,
      vodTrailer
    },
    eventPreferences,
    handleOnChangePreferences,
    isUnlocked,
    currentTab,
    handleChange,
    useImageCDN
  } = props

  const [currentPanelCalendar, setCurrentPanelCalendar] = useState(0)
  const [, setRefreshSite] = useState(0)
  const timerRef = useRef(0)

  const handleItemPanel = (event, newValue) => {
    setCurrentPanelCalendar(event.target.value)
  };

  // Set current time
  const dateNow = moment();

  // Card calentar link
  const cardLink = (item) => {
    if (item.pay && !isUnlocked) {
      return '/planes'
    } else if (dateNow.isBefore(item.dateStart) || dateNow.isAfter(item.dateEnd)){
      return null
    } else {
      return `/player/live/${item.idLive}`
    }
  };

  // Set card current moment time
  let currTagName = (dateStart, dateEnd) => { 
    if (dateNow.isBefore(dateStart)){
      return 'Pronto'
    } else if (dateNow.isAfter(dateEnd)){
      return 'Finalizado'
    } else {
      return 'En vivo'
    }
  }

  // Definir estilo para tag de eventos
  let eventTagColor = (dateStart, dateEnd) => {
    if (dateNow.isBefore(dateStart)){
      return null
    } else if (dateNow.isAfter(dateEnd)){
      return 'gray'
    } else {
      return 'red'
    }
  }

  const timerCallback = useCallback(() => {
    setRefreshSite(timerRef.current)
  }, [])

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, 10000)
    return () => {
      clearInterval(timerRef.current)
    }
  }, [timerCallback])

  return (
    <main className='appWrap fixedHero'>
      <EventHeroContianer
        vodTrailer={vodTrailer}
        imagesSite={imagesSite}
        useImageCDN={useImageCDN}
      />
      <div className='mainWidth homeRowsWrap'>
        <section className='rowXxl showInfoHeader'>
          <div className='serieData'>
            <div className='serieReaction'>
              <div className='containerReaction'>
                <img
                  src={!isEmpty(imagesSite.logo)
                    ? useImageCDN
                      ? imagesSite.logo.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                      : imagesSite.logo
                    : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-logo.png'
                  }
                  alt=''
                  className='serieLogo'
                />
                <div className='buttonGroup'>
                  <Button
                    variant='outlined'
                    startIcon={<Icons path={eventPreferences && eventPreferences.favorite ? iconStar : iconStarOutline} />}
                    onClick={() => handleOnChangePreferences({ favorite: eventPreferences && eventPreferences.favorite ? !eventPreferences.favorite : true })}
                  >
                    Favorito
                  </Button>
                  <IconButton
                    color='primary'
                    aria-label='me gusta'
                    onClick={() => handleOnChangePreferences({ thumb: eventPreferences && eventPreferences.thumb === 'UP' ? '' : 'UP' })}
                  >
                    <Icons path={eventPreferences && eventPreferences.thumb === 'UP' ? iconThumbUp : iconThumbUpOutline} />
                  </IconButton>
                  <IconButton
                    color='primary'
                    aria-label='no me gusta'
                    onClick={() => handleOnChangePreferences({ thumb: eventPreferences && eventPreferences.thumb === 'DOWN' ? '' : 'DOWN' })}
                  >
                    <Icons path={eventPreferences && eventPreferences.thumb === 'DOWN' ? iconThumbDown : iconThumbDownOutline} />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className='serieDescription'>
              <p>{resume}</p>
            </div>
          </div>
          {!isUnlocked &&
            <Link to='/planes'>
              <Button variant='contained' className='bigButtonAction'><h5>¡Suscríbete ahora!</h5><p>Prueba gratis durante 7 días.</p></Button>
            </Link>
          }
        </section>

        <section className='rowXxl'>
          {/* Sections Tab */}
          <Tabs value={currentTab} onChange={handleChange} aria-label="event tabs" className="navHorizontal">
            {sections.map((menu, index) => (
              <Tab label={menu.title} {...tabsProps(index)} key={index} />
            ))}
          </Tabs>
          {/* Sections Panels */}
          {sections.map((section, index) => (
            <TabPanelCustom value={currentTab} index={index} key={index}>
              <br />
              {section.type === "CalendarGroup" ?
                <>
                  {section.content.length > 1 &&
                    <FormControl>
                      <Select
                        labelId='calendar-select'
                        id='calendar-select'
                        value={currentPanelCalendar}
                        onChange={handleItemPanel}
                        defaultValue={0}
                        className='selectBase'
                        IconComponent={() => (<Icons path={iconArrowDown} />)}
                        style={{minWidth: '180px'}}
                      >
                        {section.content.map((item, index) => (
                          <MenuItem value={index} key={index}>{item.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  <div>
                    {section.content.map((item, index) => (
                      <TabPanelCustom value={currentPanelCalendar} index={index} key={index}>
                      <div className='calendarItems grid grid-1-cols cardLandscape' >
                        {item.calendarItems.map((item) => (
                          <li key={item._id}>
                            <CardInfoEvent
                              item={item}
                              isUnlocked={isUnlocked}
                              link={cardLink(item)}
                              useImageCDN={useImageCDN}
                              currTagName={currTagName}
                              eventTagColor={eventTagColor}
                              cardDateStart={moment(item.dateStart).format("D MMM")}
                              cardHourStart={moment(item.dateStart).format("HH[:]mm").toString()}
                              cardHourEnd={moment(item.dateEnd).format("HH[:]mm").toString()}
                            />
                          </li>
                        ))}
                      </div>
                      </TabPanelCustom>
                    ))}
                  </div>
                </>
              :
                <div className='grid grid-3-cols cardLandscape'>
                  {section.content.map((vod) => (
                    <li key={vod._id}>
                      <CardInfo
                        vod={vod}
                        isUnlocked={isUnlocked}
                        pay={vod.pay}
                        type={section.type}
                        useImageCDN={useImageCDN}
                      />
                    </li>
                  ))}
                </div>
              }
            </TabPanelCustom>
          ))}
        </section>
      </div>
      <Footer />
    </main>
  )
}